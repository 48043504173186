<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">User Notifications</span>
        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{ totalNotifications }} new
        </span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            :data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Notifications
          </a>
        </li>
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            :data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Logs
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-8">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative"
        >
          <template v-if="notification.length > 0">
            <template v-for="(item, i) in notification">
              <!--begin::Item-->
              <div
                class="d-flex align-items-center mb-6"
                v-bind:key="i"
                :id="'user-notification-' + i"
              >
                <!--begin::Symbol-->
                <div
                  class="symbol symbol-40 mr-5"
                  v-bind:class="`symbol-light-${item.color}`"
                >
                  <span class="symbol-label">
                    <span
                      class="svg-icon svg-icon-lg"
                      v-bind:class="`svg-icon-${item.color}`"
                    >
                      <!--begin::Svg Icon-->
                      <inline-svg :src="item.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Text-->
                <div class="d-flex flex-column font-weight-bold d-block">
                  <a
                    href="javascript:void(0)"
                    v-on:click="pushToRoute(item)"
                    class="text-dark text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.title }}

                    <template v-if="isNewNotification(item.added_at)">
                      <span
                        class="label ml-2 label-light-success label-inline font-weight-bold"
                      >
                        new
                      </span>
                    </template>
                  </a>
                  <span class="text-muted">
                    {{ item.desc }}
                  </span>
                  <span class="text-muted">
                    {{ formatDateTime(item.added_at) }}
                  </span>
                </div>
                <!--end::Text-->
              </div>
              <template v-if="item.tooltip">
                <b-tooltip
                  v-bind:key="'tooltip' + i"
                  :target="'user-notification-' + i"
                  :title="item.tooltip"
                ></b-tooltip>
              </template>
              <!--end::Item-->
            </template>
          </template>
          <template v-else>
            <div class="d-flex flex-center text-center text-muted min-h-200px">
              All caught up!<br />No new notifications.
            </div>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <div class="d-flex flex-center text-center text-muted min-h-200px">
          All caught up!<br />No new logs.
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import moment from "moment-timezone";
import Pusher from "pusher-js";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0,
      currentUser: null,
      notification: [],
      logs: [],
    };
  },
  mounted() {
    this.getNotifications();
    if (this.lodash.isEmpty(this.currentUser) === false) {
      this.subscribe();
    }
  },
  created() {
    this.currentUser = this.getCurrentUser();
  },
  methods: {
    pushToRoute(item) {
      if (item.route) {
        this.$router.push(
          this.getDefaultRoute(item.route.name, {
            params: item.route.params,
          })
        );
      }
    },
    isNewNotification(date) {
      if (date) {
        let newDate = moment().add(1, "days");
        return moment(newDate).isAfter();
      }
      return false;
    },
    getNotifications() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "notifications" })
        .then(({ data }) => {
          _this.notification = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    subscribe() {
      const _this = this;
      let user_id = _this.lodash.toSafeInteger(_this.currentUser.id);
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
        cluster: "ap2",
        encrypted: true,
      });
      pusher.subscribe("genic.app.notifications." + user_id);
      pusher.bind("App\\Events\\GenicAppNotifications", (response) => {
        _this.notification.unshift(response.data);
      });
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
  },
  computed: {
    backgroundImage() {
      return process.env.VUE_APP_BASE_URL + "media/misc/bg-1.jpg";
    },
    totalNotifications() {
      let notificationCount = this.notification.length;
      let logsCount = this.logs.length;
      return (
        this.lodash.toSafeInteger(notificationCount) +
        this.lodash.toSafeInteger(logsCount)
      );
    },
  },
};
</script>
